.dashboard-container {
  padding: 40px;
}
.dashboard-container .dashboard-item {
  box-shadow: inset -1px 1px 5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: 0.3s all;
}

.dashboard-container .dashboard-item:hover {
  box-shadow: 0px 0px 20px rgb(0 0 0 / 50%);
}

.dashboard-container .dashboard-container-body {
  margin: 30px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, 120px);
  justify-items: center;
  justify-content: center;
  gap: 2.5rem;
}