.modalInput label{
color:green
}

@media only screen and (max-width: 1023px) {
  .top-profile-name {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 900px) {
  .user-details {
    margin-right: 0 !important;
  }
  .aboutProfile {
    margin: 0 !important;
  }
  .contactIcon {
    margin-left: 0 !important;
  }
  .PersonalInformation {
    margin: 0 !important;
    padding-bottom: 15px !important;
  }
  .custom-column-user-details .custom-card,
  .custom-column-user-details .notice-card,
  .custom-column-user-details .voter-search-card {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}


@media only screen and (max-width: 768px) {

  .container-9-3 {
    padding: 30px 40px !important;
  }
  .PersonalInformation {
    padding: 0 !important;
    padding-bottom: 15px !important;
  }
}




@media only screen and (max-width: 500px) {

  .container-9-3 {
    padding: 30px 20px !important;
  }
}
@media only screen and (max-width: 400px) {

  .container-9-3 {
    padding: 30px 20px !important;
  }
  h6.top-profile-name {
    font-size: 17px;
  }
  p.top-profile-name {
    font-size: 15px;
  }
  .PersonalInformation * {
    font-size: 14px;
  }
}
