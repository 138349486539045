.contruction-container {
  margin: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 300px;
  text-align: center;
  min-height: calc(100vh - 390px);
}

.contruction-container img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  opacity: 0.2;
  z-index: -1;
}

.contruction-container h1 {
  font-size: 30px;
  font-weight: 700;
}