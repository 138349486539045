
.title-container .mini-profile-info {
  margin: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.title-container .mini-profile-info svg {
  font-size: 30px;
}

.title-container .social-icons {
  text-align: end;
}