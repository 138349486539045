@media only screen and (max-width: 900px) {
  .library .minw-900 {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .library th, 
  .library td {
    padding: 5px 5px;
  }
}

@media only screen and (max-width: 600px) {
  .library .minw-600 {
    display: none;
  }
  .form-container.library-search {
    grid-template-columns: 1fr;
    gap: 0;
  }
}