.about-us-container .about-middle-img {
  display: grid;
  justify-content: center;
}

.about-us-container .bottom-social-share h3 {
  font-size: 24px;
  font-weight: bold;
  color: #197659;
}
.about-us-container .member-search-card,
.about-us-container .voter-search-card,
.about-us-container .notice-card {
  margin: 0px 15px;
  margin-top: 20px;
}





@media only screen and (max-width: 1023px) { 
  .about-us-container .member-search-card {
    width: auto;
    margin-top: 30px;
  }
}