.gallery-container {
}

.gallery-container img {
  max-width: 100%;
}

.gallery-container .custom-card-body {
  box-shadow: none;
  border: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  gap: 20px;
  transition: all 0.4s;
}

.gallery-container .image-card-container {
  position: relative;
  cursor: pointer;
  user-select: none;
}

.gallery-container .image-card-container .card-count {
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  height: 40px;
  background-color: #3bacb6;
  color: #fff;
  font-size: 14px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.gallery-container .image-card-container .card-footer {
  background-color: #3bacb6;
  color: #fff;
  text-align: center;
  padding: 10px;
  font-size: 18px;
}

.gallery-container .image-viewer-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1005 !important;
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.gallery-container .image-viewer-container.hidden {
  /* display: none; */
  visibility: hidden;
}

.gallery-container .image-viewer-container .image-viewer-inner-pre {
  background-color: #fff;
  overflow: hidden;

  opacity: 1;
  margin-bottom: 0;
  transition: 1s all;
}
.gallery-container .image-viewer-container.hidden .image-viewer-inner-pre {
  margin-bottom: 400px;
  opacity: 0;
}

.gallery-container .image-viewer-container .image-viewer-inner {
  background-color: #fff;
  width: 650px;
  position: relative;
  overflow: hidden;
  margin: 0 10px;
}
.gallery-container .image-viewer-container .slider-body {
  transition: margin 1s;
  overflow: hidden;
}
.gallery-container .image-viewer-container .slider-body-item {
  display: inline-block;
  /* width: calc(); */
}
.gallery-container .image-viewer-container .slider-body-item img {
  width: 650px;
}

.gallery-container .image-viewer-container h4.top-title {
  background-color: #3bacb6;
  text-align: center;
  padding: 10px;
  color: #fff;
  position: relative;
}

.gallery-container .image-viewer-container h4.top-title .times-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.gallery-container .image-viewer-container .slider-body-item {
  position: relative;
}


.gallery-container .image-viewer-container h4.bottom-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  background-color: rgba(47, 20, 20, 0.8);
  padding: 10px;
} 


.gallery-container .image-viewer-container span.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px;
  cursor: pointer;
  user-select: none;
}

.gallery-container .image-viewer-container span.arrow svg {
  font-size: 25px;
}

.gallery-container .image-viewer-container span.arrow svg path {
  color: #fff;
}

.gallery-container .image-viewer-container span.right-arrow {
  right: 0;
}

@media only screen and (max-width: 992px) { 
  .gallery-container .image-card-container .card-footer {
    padding: 5px;
    font-size: 16px;
  }
  .gallery-container .custom-card-body {
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
}


@media only screen and (max-width: 650px) { 
  .gallery-container .custom-card-body {
    grid-template-columns: 1fr;
  }
}