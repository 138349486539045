.about-us-container .constitution-iframe iframe {
  width: 100%;
  height: 500px;
}

.about-us-container .about-middle-download-pdf {
  margin: 20px 0;
}
.about-us-container .about-middle-download-pdf button {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  justify-content: center;
  align-items: center;
}